<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Perubahan Nomenklatur</p>
            <v-spacer />
          </v-card-actions>
          <v-divider class="mb-5" />
          <v-row class="px-2">
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0 pa-1"
            >
              <v-select
                :items="lists.types"
                v-model="filter.type"
                placeholder="Tipe Unit"
                outlined
                dense
                hide-details
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0 pa-1"
              v-if="
                filter.type == 'unit-kerja-2' ||
                  filter.type == 'unit-kerja-3' ||
                  filter.type == 'unit-kerja-4'
              "
            >
              <v-autocomplete
                :items="lists.unit_utama"
                v-model="filter.unit_utama"
                :loading="loadingUnitUtama"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0 pa-1"
              v-if="
                filter.type == 'unit-kerja-3' || filter.type == 'unit-kerja-4'
              "
            >
              <v-autocomplete
                :items="lists.unit_kerja_2"
                v-model="filter.unit_kerja_2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0 pa-1"
              v-if="filter.type == 'unit-kerja-4'"
            >
              <v-autocomplete
                :items="lists.unit_kerja_3"
                v-model="filter.unit_kerja_3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              xs="12"
              class="my-0 pa-1"
            >
              <v-text-field
                placeholder="Masukan Keyword"
                v-model="filter.search"
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="px-2">
            <v-spacer />
            <v-btn color="#FBB005" class="px-10" @click="getListNomenklatur">
              <span class="subtitle-2 text-capitalize">Cari</span>
            </v-btn>
          </v-card-actions>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="units"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.isActive`]="{ item }">
              <span>{{ item.isActive ? "Aktif" : "Tidak Aktif" }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon small @click="edit(item)" color="primary"
                ><v-icon small>edit</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Ubah {{ filter.type | dashToTitle }}
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="3" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                v-model="selected.nama"
                :rules="[v => !!v || 'Nama harus diisi']"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Singkatan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field
                v-model="selected.singkatan"
                :rules="[v => !!v || 'Singkatan harus diisi']"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Ubah</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NomenklaturService from "@/services/resources/nomenklatur.service";
import PublicService from "@/services/resources/public.service";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      loadingUnit: false,
      valid: false,
      loadingUnitUtama: false,
      formLoading: false,
      lists: {
        unit_utama: [],
        unit_kerja_temp: [],
        unit_kerja_2: [],
        unit_kerja_3: [],
        types: [
          {
            text: "Unit Utama",
            value: "unit-utama"
          },
          {
            text: "Unit Kerja 2",
            value: "unit-kerja-2"
          },
          {
            text: "Unit Kerja 3",
            value: "unit-kerja-3"
          },
          {
            text: "Unit Kerja 4",
            value: "unit-kerja-4"
          }
        ]
      },
      filter: {
        search: null,
        type: "unit-utama",
        unit_utama: null,
        unit_kerja_2: null,
        unit_kerja_3: null
      },
      options: {
        sortBy: ["singkatan"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      units: [],
      selected: {
        id: null,
        nama: null,
        singkatan: null,
        isActive: null
      },
      headers: [
        { text: "NO", value: "no" },
        { text: "NAMA", value: "nama" },
        { text: "SINGKATAN", value: "singkatan" },
        { text: "AKTIF", value: "isActive" },
        { text: "", value: "actions" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(() => this.getListNomenklatur());
      },
      deep: true
    }
  },
  methods: {
    changeUnit(type = 2) {
      if (type == 1) {
        this.lists.unit_kerja_2 =
          this.lists.unit_kerja_temp &&
          this.lists.unit_kerja_temp.filter(d =>
            d.unit_kerja_2_id.includes(this.filter.unit_utama)
          );
        this.filter.unit_kerja_2 = null;
        this.filter.unit_kerja_3 = null;
        this.filter.unit_kerja_4 = null;
      } else if (type == 2) {
        this.lists.unit_kerja_3 =
          this.lists.unit_kerja_2 &&
          this.lists.unit_kerja_2.find(
            d => d.unit_kerja_2_id == this.filter.unit_kerja_2
          )?.unit_kerja3;
        this.filter.unit_kerja_3 = null;
        this.filter.unit_kerja_4 = null;
      }
    },
    edit(item) {
      this.$_.merge(this.selected, item);
      this.visible = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.updateNomenklatur();
      }
    },
    async getListUnitUtama() {
      try {
        this.loadingUnitUtama = true;
        await PublicService.getUnitUtamaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.lists.unit_utama = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnitUtama = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await PublicService.getUnitKerjaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.lists.unit_kerja_temp = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListNomenklatur() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await NomenklaturService.getList({
          filter: {
            search: this.filter.search,
            type: this.filter.type,
            unit_utama_id: this.filter.unit_utama,
            unit_kerja_2_id: this.filter.unit_kerja_2,
            unit_kerja_3_id: this.filter.unit_kerja_3
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let units = list;
              units.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.units = units;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async updateNomenklatur() {
      try {
        this.formLoading = true;
        await NomenklaturService.update(this.selected.id, {
          type: this.filter.type,
          nama: this.selected.nama,
          singkatan: this.selected.singkatan
        })
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListNomenklatur();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnitUtama();
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#card-custom .v-input {
  font-size: 0.775rem;
}
#card-custom .v-input {
  &.v-input--is-disabled {
    background-color: #f0f0f7;
  }
}
</style>
