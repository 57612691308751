import BaseInstance from "../base.instance";
import { NOMENKLATUR_LIST, NOMENKLATUR_UPDATE } from "../constants";

const NomenklaturService = {
  getList(data) {
    return BaseInstance.post(NOMENKLATUR_LIST, data);
  },
  update(id, data) {
    return BaseInstance.create(NOMENKLATUR_UPDATE, id, data);
  }
};

export default NomenklaturService;
